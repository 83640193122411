@import '../../sass/functions.scss';
@import '../../sass/variables';

.cst-table {
    font-size: 1.6vh;
    thead {
        th {
            border-right: solid 1px #e6e6e6;
            border-bottom-color: #333;
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: toRem(16);

            &:first-child {
                /* border-right: none; */
            }
            &:nth-child(2) {
                /*  padding-left: 0; */
            }
            &:last-child {
                border-right: none;
            }
        }
    }
    .sortingth {
        vertical-align: middle;
        svg {
            width: toRem(14);
            height: auto;
            margin-top: 3px;
            margin-left: 5px;
        }
    }
    tbody {
        td,
        th {
            color: #757575;
            font-size: toRem(16);
            border-color: #e6e6e6;
            border-right: solid 1px #e6e6e6;
            &:first-child {
                /* border-right: none; */
                text-align: center;

                label {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
            &:last-child {
                border-right: none;
            }
        }
    }
    .optionItem {
        input + svg {
            width: toRem(18);
            height: auto;
        }
    }
    .status {
        display: inline-block;
        padding: toRem(3) toRem(11) toRem(5);
        font-size: toRem(14);
        border-radius: toRem(15);
        line-height: normal;
        text-transform: capitalize;
        font-weight: 500;
        &.completed {
            background-color: #defae6;
            color: #007f26;
        }
        &.raw_ppt_generated {
            background-color: #fff3ce;
            color: #c58200;
        }
        &.queue {
            background-color: #fff3ce;
            color: #c58200;
        }
        &.failed {
            /* background-color: $pink100; */
            background-color: #fde7e7;
            color: #d51f31;
        }
        &.raw_ppt_generating {
            /* background-color: $yellow100; */
            background-color: #fff3ce;
            color: #c58200;
        }
    }
    .action-btn {
        button {
            font-size: toRem(14);
            color: #2b5580;
        }
    }
}
