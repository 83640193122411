@import '../../sass/variables';
@import '../../sass/functions';

.wrapper {
    height: rem(100);
    position: relative;
    background-image: url('./assets/bg_login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .loader {
        background: transparent !important;
    }

    .loginWrapper {
        background: $white;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -35%);
        padding: rem(8) rew(4);
        border-radius: 8px;
        text-align: center;

        .loginHead {
            margin-bottom: rem(2);
        }

        .loginButton {
            padding: 0 rew(3);
            background: $green600;
            border-radius: 12px;
            font-size: rem(2);
            cursor: pointer;
            line-height: rem(8);

            img {
                position: relative;
                margin-right: rew(1);
                top: 3px;
            }

            & > span {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
}
